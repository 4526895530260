import React, { Component } from "react";
import Auth from "../common/Auth";
import Config from "../Config";
import Parser from 'html-react-parser';

/**
 * 
 * Displays FAQ page
 * 
 */
export default class Faqs extends Component {
  constructor() {
    super();
    this.auth = new Auth();
    this.config = new Config();
    this.$http = this.config.DConnect();
    this.state = {
      faq:{title:'', content:''},
      titles: {'ticket':'', 'pay':'','win':'','result':'','Alpha':'','general':''},
      tabs: {'ticket':[], 'pay':[],'win':[],'result':[],'alpha':[],'general':[]}
    };
  }

  componentDidMount = () => {
    this.getContent();
  }

  getContent = () => {
    this.$http.get('web/web/portal/4')
      .then(res => {
        let content = this.auth.decryptData(res.data);
        
        let faq = content.filter(ct => ct.category===4 && ct.categorySub===0);
        let ticket = content.filter(ct => ct.category==4 && ct.categorySub==1);
        let tickets = content.filter(ct => ct.category==4 && ct.categorySub==11);
        let pay = content.filter(ct => ct.category==4 && ct.categorySub==2);
        let pays = content.filter(ct => ct.category==4 && ct.categorySub==12);
        let win = content.filter(ct => ct.category==4 && ct.categorySub==3);
        let wins = content.filter(ct => ct.category==4 && ct.categorySub==13);
        let result = content.filter(ct => ct.category==4 && ct.categorySub==4);
        let results = content.filter(ct => ct.category==4 && ct.categorySub==14);
        let alpha = content.filter(ct => ct.category==4 && ct.categorySub==5);
        let alphas = content.filter(ct => ct.category==4 && ct.categorySub==15);
        let general = content.filter(ct => ct.category==4 && ct.categorySub==6);
        let generals = content.filter(ct => ct.category==4 && ct.categorySub==16);

        let titles = {
          'ticket':ticket[0].title, 'pay':pay[0].title,'win':win[0].title,
          'result':result[0].title,'alpha':alpha[0].title,'general':general[0].title
        }
        let tabs = {
          'ticket':tickets, 'pay':pays,'win':wins, 'result':results,'alpha':alphas,'general':generals
        }
        //alert(JSON.stringify(news))
        this.setState({ faq: faq[0], titles, tabs });
      }).catch((error) => { console.log(error.message) })
  }

  render() {
    let {faq, titles, tabs} = this.state;
    let cnt = 0;

    return (
      <div>
        <section class="inner-page-banner has_bg_image" data-background="assets/images/inner-page-bg.jpg" style={{backgroundImage:'url(assets/images/inner-page-bg.jpg)'}}>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="inner-page-banner-area">
                  <h1 class="page-title">FAQ</h1>
                  <nav aria-label="breadcrumb" class="page-header-breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="">Home</a></li>
                      <li class="breadcrumb-item active">FAQ</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="faq-section section-padding">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="section-header text-center">
                  <h2 class="section-title">{faq.title}</h2>
                  {faq.content}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="faq-wrapper">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link" id="ticket-tab" data-toggle="tab" href="#ticket" role="tab" aria-controls="ticket" aria-selected="false">{titles.ticket}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="banking-tab" data-toggle="tab" href="#banking" role="tab" aria-controls="banking" aria-selected="false">{titles.pay}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="winning-tab" data-toggle="tab" href="#winning" role="tab" aria-controls="winning" aria-selected="false">{titles.win}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" id="results-tab" data-toggle="tab" href="#results" role="tab" aria-controls="results" aria-selected="true">{titles.result}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="about-tab" data-toggle="tab" href="#about" role="tab" aria-controls="about" aria-selected="false">{titles.alpha}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="false">{titles.general}</a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade" id="ticket" role="tabpanel" aria-labelledby="ticket-tab">
                      <div class="accordion sorteo-accordion" id="accordionExample">
                        {tabs.ticket.map((tick, idx) => (
                        <div class="card">
                          <div class="card-header" id={`heading1${idx}`}>
                            <h2 class="mb-0">
                              <button class="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse1${idx}`} aria-expanded="true" aria-controls={`collapse1${idx}`}><i class="fa fa-question"></i> {tick.title}</button>
                            </h2>
                          </div>
                          <div id={`collapse1${idx}`} class="collapse" aria-labelledby={`heading1${idx}`} data-parent="#accordionExample">
                            <div class="card-body">{Parser(tick.content)}</div>
                          </div>
                        </div>
                        ))}
                      </div>
                    </div>
                    <div class="tab-pane fade" id="banking" role="tabpanel" aria-labelledby="banking-tab">
                      <div class="accordion sorteo-accordion" id="accordionExample2">
                        {tabs.pay.map((tick, idx) => (
                          <div class="card">
                            <div class="card-header" id={`heading2${idx}`}>
                              <h2 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse2${idx}`} aria-expanded="true" aria-controls={`collapse2${idx}`}><i class="fa fa-question"></i> {tick.title}</button>
                              </h2>
                            </div>
                            <div id={`collapse2${idx}`} class="collapse" aria-labelledby={`heading2${idx}`} data-parent="#accordionExample">
                              <div class="card-body">{Parser(tick.content)}</div>
                            </div>
                          </div>
                          ))}
                      </div>
                    </div>
                    <div class="tab-pane fade" id="winning" role="tabpanel" aria-labelledby="winning-tab">
                      <div class="accordion sorteo-accordion" id="accordionExample3">
                        {tabs.win.map((tick,idx) => (
                          <div class="card">
                            <div class="card-header" id={`heading3${idx}`}>
                              <h2 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse3${idx}`} aria-expanded="true" aria-controls={`collapse3${idx}`}><i class="fa fa-question"></i> {tick.title}</button>
                              </h2>
                            </div>
                            <div id={`collapse3${idx}`} class="collapse" aria-labelledby={`heading3${idx}`} data-parent="#accordionExample">
                              <div class="card-body">{Parser(tick.content)}</div>
                            </div>
                          </div>
                          ))}
                      </div>
                    </div>
                    <div class="tab-pane fade show active" id="results" role="tabpanel" aria-labelledby="results-tab">
                      <div class="accordion sorteo-accordion" id="accordionExample4">
                        {tabs.result.map((tick,idx) => (
                          <div class="card">
                            <div class="card-header" id={`heading4${idx}`}>
                              <h2 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse4${idx}`} aria-expanded="true" aria-controls={`collapse4${idx}`}><i class="fa fa-question"></i> {tick.title}</button>
                              </h2>
                            </div>
                            <div id={`collapse4${idx}`} class="collapse" aria-labelledby={`heading4${idx}`} data-parent="#accordionExample">
                              <div class="card-body">{Parser(tick.content)}</div>
                            </div>
                          </div>
                          ))}
                      </div>
                    </div>
                    <div class="tab-pane fade" id="about" role="tabpanel" aria-labelledby="about-tab">
                      <div class="accordion sorteo-accordion" id="accordionExample5">
                        {tabs.alpha.map((tick,idx) => (
                          <div class="card">
                            <div class="card-header" id={`heading5${idx}`}>
                              <h2 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse5${idx}`} aria-expanded="true" aria-controls={`collapse5${idx}`}><i class="fa fa-question"></i> {tick.title}</button>
                              </h2>
                            </div>
                            <div id={`collapse5${idx}`} class="collapse" aria-labelledby={`heading5${idx}`} data-parent="#accordionExample">
                              <div class="card-body">{Parser(tick.content)}</div>
                            </div>
                          </div>
                          ))}
                      </div>
                    </div>
                    <div class="tab-pane fade" id="general" role="tabpanel" aria-labelledby="general-tab">
                      <div class="accordion sorteo-accordion" id="accordionExample6">
                        {tabs.general.map((tick,idx) => (
                          <div class="card">
                            <div class="card-header" id={`heading6${idx}`}>
                              <h2 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse6${idx}`} aria-expanded="true" aria-controls={`collapse6${idx}`}><i class="fa fa-question"></i> {tick.title}</button>
                              </h2>
                            </div>
                            <div id={`collapse6${idx}`} class="collapse" aria-labelledby={`heading6${idx}`} data-parent="#accordionExample">
                              <div class="card-body">{Parser(tick.content)}</div>
                            </div>
                          </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}