import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config';
import moment from 'moment'
import axios from 'axios'
import $ from 'jquery'
import NumberFormat from 'react-number-format';

/**
 * 
 * Form Writers use to purchase Unit
 * 
 */
export default class PurchaseUnitForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth();
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: {
                usertype:"1", unito:this.auth.getUser(), unitfrom:'Alpha', amount:0,
                transtype:2, totalUnit:0.00, commission:0.00,
                curuser: this.auth.getUser(), token:''
            },
            unit:0.00,
            wallet:0.00
        }
    }

    componentDidMount = () => {
        this.getUserTill();
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps !== this.state){
            //this.getUserTill();
        }
    }

    getUserTill = () => {
        this.$http.get('web/web/' + this.auth.getUser() + '/usertill')
        .then(res => {
            let usertill = this.auth.decryptData(res.data)

            let unit = usertill.filter(rdata => rdata.tillType == '1' && rdata.status)
            let wallet = usertill.filter(rdata => rdata.tillType == '2' && rdata.status);

            this.setState({ unit: unit[0], wallet: wallet[0] });
        }).catch(error => {alert(error.message)});
    }

    handleChange = (e) => {
        let {formItems,wallet} = this.state;
        let val = parseFloat(e.target.value.replace(/,/gi, ''))
        val = val>0?val:0;
        val = val>wallet.closeBal ? parseFloat(wallet.closeBal): val;

        formItems[e.target.name] = val;
        formItems['commission'] = (((100/80) * val) - val).toFixed(2);

        this.setState({ formItems });
    }

    formSubmit = (e) => {
        e.preventDefault();

        $('#btnPurchaseCredit').hide();
        let {formItems} = this.state
        formItems['totalUnit'] = parseFloat(formItems.amount) + parseFloat(formItems.commission);
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'web/web/momounit'))
        .then(res => {
            alert(this.auth.decryptText(res.data));

            $('#btnPurchasecancel').click();
            this.props.getPurchased();
        }).catch((error)=>{alert(error );$('#btnPurchaseCredit').show()})
    }

    render(){
        let { formItems, wallet } = this.state;
        return(
            <div className="modal fade login-modal" id="purchaseUnitModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h3>Purchase Unit</h3></div>
                        <div className="modal-body">
                            <form id="frmPurchaseUnit" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Wallet Balance: </label>
                                        <span className="ctrltxt">{(parseFloat(wallet.closeBal)).toFixed(2)}</span>
                                    </div>
                                    <div className="col-12">
                                        <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Unit Amount: </label>
                                        <NumberFormat displayTtype="text" min="0" step="any"  id="amount" name="amount" style={{width:'200px'}}
                                            required thousandSeparator={true} isNumericString={true} allowNegative={false} allowEmptyFormatting={true}
                                            value={formItems.amount} onChange={this.handleChange} />
                                    </div>
                                    <div className="col-12">
                                        <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Commission: </label>
                                        <span className="ctrltxt">{formItems.commission}</span>
                                    </div>
                                    {/* <div className="col-12">
                                        <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Bonus: </label>
                                        <span className="ctrltxt">{formItems.bonus}</span>
                                    </div> */}
                                    <div className="col-12">
                                        <label style={{width:'120px', marginRight:'10px'}} className='text-right'>Total Unit: </label>
                                        <span className="ctrltxt">{(parseFloat(formItems.commission)+parseFloat(formItems.amount)).toFixed(2)}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnPurchasecancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button id="btnPurchaseCredit" type="submit" form="frmPurchaseUnit" className="btn btn-primary">Purchase</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}